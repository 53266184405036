import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useEffect } from "react";
import Clouds from "./Components/Models/Cloud";
import { Html, useScroll } from "@react-three/drei";
import MysteryIsland from "./Components/Models/MysteryIsland";
import Voxelotl from "./Components/Models/Voxelotl";
import WispyUI from "./Components/UI/WispyUI";
import banner from "./img/banner.png";
import NormalText from "./Components/UI/NormalText";
import { isMobile } from "react-device-detect";
import { useState } from "react";

function Scene() {
  const maxScale = 950;
  const minScale = 450;
  const { camera, gl, size, viewport } = useThree();
  const lightRef = useRef();
  const scroll = useScroll();
  const [htmlPosition, setHtmlPosition] = useState();
  const [descPosition, setDescPosition] = useState();

  const startPosition = { x: 0, y: 250, z: 1000 };
  const endPosition = { x: 14, y: 60, z: 0 };

  useEffect(() => {
    const width = size.width;
    const height = size.height;

    isMobile
      ? setHtmlPosition([13.35, 81, 16])
      : setHtmlPosition([28, 58, -16]);

    isMobile ? setDescPosition([14, 73.5, 8]) : setDescPosition([0, 58, -16]);

    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    gl.setSize(width, height, false);
  }, [camera, gl, size, minScale, maxScale, isMobile]);

  useFrame(() => {
    // Calculate the new camera position based on the scroll offset
    const offset = isMobile
      ? Math.min(0.95, Math.max(0, scroll.offset))
      : Math.min(1, Math.max(0, scroll.offset));
    const newPosition = {
      x: startPosition.x + (endPosition.x - startPosition.x) * offset,
      y: startPosition.y + (endPosition.y - startPosition.y) * offset,
      z: startPosition.z + (endPosition.z - startPosition.z) * offset,
    };

    // Update the camera position
    camera.position.set(newPosition.x, newPosition.y, newPosition.z);
  });

  return (
    <group>
      <directionalLight
        intensity={5}
        rotation={[5, 0, 0]}
        color={"white"}
        ref={lightRef}
      />
      <ambientLight intensity={3} color={"white"} />
      <Clouds position={[0, 250, 950]} count={250} />
      <Html
        transform
        portal={{ current: scroll.fixed }}
        position={htmlPosition}
      >
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/HkUjUNehyTk"
          title="Release Trailer"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </Html>
      <MysteryIsland />
      <Voxelotl position={[16, 11, -125]} />
      <WispyUI
        url={"https://store.steampowered.com/app/1285860/Voxelotl_Garden/"}
        imgSrc={banner}
        isVideo={false}
        text={"Early Access\non Steam!"}
        fontSize={10}
        position={[8, 25, -180]}
      />
      <NormalText
        fontSize={10}
        color={"gold"}
        text={"Virtual Pet Simulator"}
        position={[16, 108, -180]}
        strokeColor={"red"}
      />
      {/* <WobbleUI position={[0, 60, -16]} scale={[14, 12, 1]} /> */}
      <mesh position={descPosition} scale={[14, 3, 1]}>
        <planeGeometry args={[1, 1, 32, 32]} />
        <meshBasicMaterial transparent opacity={0.25} color={"black"} />
      </mesh>
      <NormalText
        fontSize={1}
        color={"gold"}
        text={"Raise, train, and breed your\nvery own Voxelotl creatures!"}
        position={descPosition}
        strokeColor={"red"}
        strokeWidth={0.01}
      />
    </group>
  );
}

export { Scene as default };
