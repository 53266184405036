import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";

export const useTouchEvents = (onTouchEnd, onClick) => {
  const touchRef = useRef({ x: 0, y: 0 });
  const { invalidate } = useThree();

  useEffect(() => {
    const handleTouchMove = (e) => {
      const touch = e?.touches[0];
      touchRef.current.x = (touch?.clientX / window.innerWidth) * 2 - 1; // Normalize x position
      touchRef.current.y = -(touch?.clientY / window.innerHeight) * 2 + 1; // Normalize y position
      invalidate();
    };

    const handlePointerMove = (e) => {
      touchRef.current.x = (e?.clientX / window.innerWidth) * 2 - 1; // Normalize x position
      touchRef.current.y = -(e?.clientY / window.innerHeight) * 2 + 1; // Normalize y position
      invalidate();
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("pointermove", handlePointerMove);

    if (onTouchEnd) {
      document.addEventListener("touchend", onTouchEnd);
    }
    if (onClick) {
      document.addEventListener("pointerup", onClick);
    }
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("pointermove", handlePointerMove);
      if (onTouchEnd) {
        document.removeEventListener("touchend", onTouchEnd);
      }
      if (onClick) {
        document.removeEventListener("pointerup", onClick);
      }
    };
  }, [invalidate, touchRef, onClick, onTouchEnd]);

  return {
    touchPosition: touchRef.current,
  };
};
