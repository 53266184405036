import { Text } from "@react-three/drei";
import { Color } from "three";
import { DEFAULT_FONT_TYPE } from "../../Utils/Constants";

const NormalText = ({ ...props }) => {
  const color = props.color ?? new Color("white");

  const style = {
    font: DEFAULT_FONT_TYPE,
    fontSize: props.fontSize ?? 1,
    strokeColor: "#000",
    strokeWidth: 0.1,
    letterSpacing: -0.06,
    center: true,
    textAlign: "center",
    style: { position: "relative" },
  };

  return (
    <Text {...style} {...props}>
      <meshBasicMaterial toneMapped={false} color={color} />
    </Text>
  );
};

export default NormalText;
