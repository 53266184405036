import { useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { gsap } from "gsap";
import { Color, DoubleSide, MathUtils, Vector2 } from "three";
import { useThree } from "@react-three/fiber";
import { useVideo, useTexture, useTouchEvents } from "../../Hooks";
import vertexShader from "../../shaders/vertexShader";
import fragmentShader from "../../shaders/fragmentShader";
import { GradientTexture } from "@react-three/drei";
import { getFileName } from "../../Utils/Helpers";
import NormalText from "./NormalText";

const RAYCAST_LAYER = 1;
const PLANE_SIZE = 75.0;
const RADIUS = 0.35;

const WispyUI = ({ url, imgSrc, isVideo, text, ...props }) => {
  const groupRef = useRef(null);
  const meshRef = useRef(null);
  meshRef.current?.layers.enable(RAYCAST_LAYER);
  const [isHovered, setIsHovered] = useState(false); // Hover state

  const handlePointerClick = (e) => {
    e.stopPropagation();

    if (!url || !isHovered) return;
    window.open(url, "_blank");
  };

  const { touchPosition } = useTouchEvents();

  const texture = useTexture(imgSrc);
  const video = useVideo(imgSrc);

  const imgWidth = texture[0].image.width;
  const imgHeight = texture[0].image.height;

  const map = isVideo ? video[0] : texture[0];

  let label = text;
  if (!label) {
    label = getFileName(imgSrc);
  }

  const { raycaster, invalidate } = useThree();
  raycaster.layers.set(RAYCAST_LAYER);

  const speed = {
    value: 0.006,
  };

  let a = 0;
  let da = 0.05;

  let rand = MathUtils.clamp(0.1 + Math.random(), 0.025, 0.035);

  const dimmingFactor = isHovered ? 0.85 : 1;

  const uniforms = useMemo(
    () => ({
      uColor: { value: new Color("#FFFFFF") },
      uOuterColor: { value: new Color("#FFFFFF") },
      uPlaneSize: { value: new Vector2(PLANE_SIZE, PLANE_SIZE) },
      uImageSize: { value: new Vector2(imgWidth, imgHeight) },
      uMousePos: { value: new Vector2(0.0, 0.0) },
      uMouseRadius: { value: 0.0 },
      uTime: { value: 0.0 },
      uRadius: { value: RADIUS },
      uTexture: { value: map },
      uSpikes: { value: 6 }, // adjust the waviness
      uDimmingFactor: { value: dimmingFactor },
    }),
    []
  );

  useEffect(() => {
    uniforms.uDimmingFactor.value = dimmingFactor;
  }, [dimmingFactor, uniforms]);

  useFrame(({ camera }) => {
    if (!groupRef.current || !meshRef.current) return;

    uniforms.uTime.value += speed.value;

    a += da;

    meshRef.current.position.x =
      groupRef.current.position.x + rand * Math.sin(a);
    meshRef.current.position.y =
      groupRef.current.position.y + rand * Math.cos(a);

    raycaster.setFromCamera(touchPosition, camera);
    const intersects = raycaster.intersectObject(meshRef.current);
    const [firstIntersect] = intersects || [];
    const { uv: point } = firstIntersect || {};

    if (point) {
      setIsHovered(true);
      uniforms.uMousePos.value = point;
      gsap.to(uniforms.uMouseRadius, {
        value: 0.05,
        duration: 0.4,
        overwrite: true,
      });

      gsap.to(uniforms.uRadius, {
        value: 0.55,
        duration: 0.5,
        overwrite: true,
        onStart: () => {
          invalidate();
        },
        onComplete: () => {
          invalidate();
        },
      });
      gsap.to(speed, {
        value: 0.02,
        duration: 0.5,
        overwrite: true,
      });
      gsap.to(uniforms.uSpikes, {
        value: 2.5,
        duration: 0.8,
        overwrite: true,
      });
    } else {
      setIsHovered(false);
      uniforms.uMouseRadius.value = 0;
      gsap.to(uniforms.uRadius, {
        value: RADIUS,
        duration: 0.25,
        overwrite: true,
        onStart: () => {
          invalidate();
        },
        onComplete: () => {
          invalidate();
        },
      });
      gsap.to(speed, {
        value: 0.006,
        duration: 1.8,
        overwrite: true,
      });
      gsap.to(uniforms.uSpikes, {
        value: 6,
        duration: 2,
        overwrite: true,
      });
      gsap.to(uniforms.uMouseRadius, {
        value: 0.0,
        duration: 0.2,
        overwrite: true,
      });
    }
  });

  return (
    <group ref={groupRef} {...props}>
      <mesh ref={meshRef} onPointerUp={handlePointerClick}>
        <planeGeometry args={[PLANE_SIZE, PLANE_SIZE, 1, 1]} />
        <Suspense fallback={<FallbackText />}>
          {isHovered && (
            <NormalText
              text={label ?? "Test"}
              fontSize={props.fontSize ?? 5}
              position={[0, 0, 0.1]}
            />
          )}
        </Suspense>
        <Suspense fallback={<FallbackMaterial />}>
          <shaderMaterial
            toneMapped={false}
            uniforms={uniforms}
            uniformsNeedUpdate={true}
            transparent={true}
            depthWrite={false}
            alphaTest={0.5}
            vertexShader={vertexShader}
            fragmentShader={fragmentShader}
            side={DoubleSide}
          />
        </Suspense>
      </mesh>
    </group>
  );
};

export default WispyUI;

const FallbackMaterial = () => {
  return (
    <meshBasicMaterial>
      <GradientTexture stops={[0, 1]} colors={["#CC00FF", "blue"]} />
    </meshBasicMaterial>
  );
};

const FallbackText = () => {
  return (
    <NormalText text={"Loading..."} fontSize={0.15} position={[0, 0, 0.1]} />
  );
};
