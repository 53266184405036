import { useVideoTexture } from "@react-three/drei";
import { DEFAULT_VIDEO } from "../Utils/Constants";
import { getExtension } from "../Utils/Helpers";

const filePath = require.context("../videos", false);

export const useVideo = (uri, props) => {
  if (!props) {
    props = {
      unsuspend: "canplaythrough",
      muted: true,
      loop: true,
      start: true,
      crossOrigin: "Anonymous",
    };
  }
  const input = getValidInput(uri);
  const video = useVideoTexture(input, props);
  return [video];
};

const getValidInput = (uri) => {
  if (!isVideo(uri)) return filePath(DEFAULT_VIDEO);
  const isStatic = uri.split("/")[1] === "static";
  const input = !isStatic ? filePath(uri) : uri;
  return input;
};

// Source: https://stackoverflow.com/a/7977314
const isVideo = (filename) => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      // etc
      return true;
    default:
      return false;
  }
};
