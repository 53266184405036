import { useLoader } from "@react-three/fiber";
import { ImageLoader } from "three";
import { isImage } from "../Utils/Helpers";
import { DEFAULT_IMAGE } from "../Utils/Constants";

const filePath = require.context("../img", false);

export const useImage = (uri) => {
  const input = getValidInput(uri);
  const image = useLoader(ImageLoader, input);
  return image;
};

const getValidInput = (uri) => {
  if (!isImage(uri)) return filePath(DEFAULT_IMAGE);
  const isStatic = uri.split("/")[1] === "static";
  const input = !isStatic ? filePath(uri) : uri;
  return input;
};
