// Source: https://stackoverflow.com/a/7977314
export const isImage = (filename) => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
    case "svg":
      //etc
      return true;
    default:
      return false;
  }
};

export const getExtension = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const getFileName = (filename) => {
  const startString = "/media/";
  const endString = ".";

  const startIndex = filename.indexOf(startString);
  const endIndex = filename.indexOf(endString, startIndex + startString.length);

  if (startIndex !== -1 && endIndex !== -1) {
    const value = filename.substring(startIndex + startString.length, endIndex);
    return value;
  } else {
    console.log("No match found.");
    return "Default Text";
  }
};
