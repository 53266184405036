import { useAnimations, useGLTF } from "@react-three/drei";
import { useEffect } from "react";

const Voxelotl = ({ ...props }) => {
  const { nodes, scene, animations } = useGLTF("/voxelotl.glb");
  const { ref, actions } = useAnimations(animations);

  useEffect(() => {
    // Filter nodes by isMesh and exclude nodes with "eye" or "mouth" in their name
    const meshNodes = Object.values(nodes).filter(
      (node) =>
        node.isMesh &&
        !node.name.toLowerCase().includes("eye") &&
        !node.name.toLowerCase().includes("mouth")
    );

    // Loop through mesh nodes and change material color
    meshNodes.forEach((node) => {
      if (node.material) {
        node.material.color.set(0xfcc0ff); // Set material color to red
      }
    });
  }, [nodes]);

  useEffect(() => {
    actions?.Sit.play();
  }, [actions]);

  return (
    <group
      ref={ref}
      rotation={[0, (0 * Math.PI) / 180, 0]}
      scale={[10, 10, 10]}
      {...props}
    >
      <primitive object={scene} />
    </group>
  );
};

export default Voxelotl;
