import { useGLTF } from "@react-three/drei";

const MysteryIsland = () => {
  const { nodes } = useGLTF("/island.glb");
  const meshNodes = Object.values(nodes).filter((node) => node.isMesh);

  return (
    <group rotation={[0, (270 * Math.PI) / 180, 0]}>
      {meshNodes.map((node, index) => (
        <mesh key={index} geometry={node.geometry} material={node.material} />
      ))}
    </group>
  );
};

export default MysteryIsland;
