import { useFrame } from "@react-three/fiber";
import { useRef, useLayoutEffect, useState } from "react";
import { Instance, Instances, useGLTF } from "@react-three/drei";

const Clouds = ({ count, ...props }) => {
  const { nodes, materials } = useGLTF("/cloud.glb");
  return (
    <group {...props}>
      <Instances
        range={count}
        material={materials.colormap}
        geometry={nodes.cube_1.geometry}
      >
        {Array.from({ length: count }, (_, index) => (
          <Cloud key={index} />
        ))}
      </Instances>
    </group>
  );
};

const Cloud = () => {
  const ref = useRef();
  const [randomPosition, setRandomPosition] = useState({ x: 0, y: 0, z: 0 });
  const [randomScale, setRandomScale] = useState({
    scaleX: 1,
    scaleY: 1,
    scaleZ: 1,
  });
  const [randomMod, setRandomMod] = useState(1);

  useLayoutEffect(() => {
    // Generate random position values within the viewport boundaries
    const x = Math.random() * 1000 - 500;
    const y = Math.random() * 100 - 25; // Range: 0 to 8
    const z = Math.random() * -1100;

    setRandomPosition({ x, y, z });

    // Generate random scale values within a desired range
    const scaleX = Math.random() * 2 + 90;
    const scaleY = Math.random() * 2 + 30;
    const scaleZ = Math.random() * 2 + 45;

    setRandomScale({ scaleX, scaleY, scaleZ });

    // Generate random modulation factor within a desired range
    const mod = Math.random() * 2 + 0.5; // Range: 0.5 to 2.5
    setRandomMod(mod);
  }, []);

  useFrame((state) => {
    const t = state.clock.getElapsedTime() * 0.75;
    ref.current.position.y = Math.sin(t * randomMod) / 4 + randomPosition.y;

    ref.current.scale.set(
      randomScale.scaleX,
      randomScale.scaleY,
      randomScale.scaleZ
    );
  });

  return (
    <group position={[randomPosition.x, randomPosition.y, randomPosition.z]}>
      <Instance ref={ref} />
    </group>
  );
};

export default Clouds;
