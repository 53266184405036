import "./App.css";
import logo from "./img/logo.svg";
import Scene from "./Scene";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { ScrollControls } from "@react-three/drei";

function App() {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        height: "100%",
        width: "100%",
      }}
    >
      <Suspense>
        <div className="App">
          <header className="App-header">
            <div className="logo-container">
              <a href="#" className="navbar-brand">
                <img src={logo} className="App-logo" alt="logo" />
              </a>
            </div>
            <nav className="navbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </Suspense>
      <div>
        <img className="big-logo" src={logo} width={512} />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Canvas
          frameloop={"always"}
          gl={{
            antialias: false,
            powerPreference: "low-power",
          }}
          dpr={Math.max(window.devicePixelRatio, 2)}
          resize={{ polyfill: ResizeObserver }} // Allows @react-spring/three to work in Safari
        >
          <ScrollControls pages={3} damping={0.5}>
            <Scene />
          </ScrollControls>
        </Canvas>
      </Suspense>
      <footer>
        Voxelotl Garden and all related indicia are trademarks of Battleock
        Studios LLC, © 2015-2024. ® denotes Reg. USPTO. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
